import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { getUrlParams } from '../helpers/location';
import { useSelector } from 'react-redux';
import * as Api from '../apis';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import useActiveSession from '../hooks/useActiveSession';
import Layout from '../components/Layout';
import Logo from '../components/Logo';
import RatingDialog from '../components/RatingDialog';
import CrossPlatformLink from '../components/CrossPlatformLink';

const useStyles = makeStyles(theme => ({
  textInfo: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  button: {
    width: 100,
    marginTop: 20,
  },
  result: {
    padding: '0px 15px',
  },
  textBlue: {
    color: 'blue',
  },
  textRed: {
    color: 'red',
  },
  header: {
    color: 'blue',
  },
  fullButton: {
    width: '100%',
    color: 'white',
  },
  greenButton: {
    background: '#42b742',
    color: 'white',
    '&:hover': {
      background: '#42b742',
    },
  },
  redButton: {
    background: 'red',
    color: 'white',
    '&:hover': {
      background: 'red',
    },
  },
}));

const QuestionnaireSuccess = ({ location }) => {
  const classes = useStyles();
  const query = getUrlParams(location.search);
  const questionnaireId = query.questionnaireId;
  const { source } = useSelector(state => state.session);
  const [result, setResult] = useState(null);
  const [rateDialog, setRateDialog] = React.useState(false);
  const { partnerId } = useSelector(state => state.partners);

  const { verify } = useSelector(state => state.partners);

  function fetchResultData() {
    Api.getResult(questionnaireId).then(data => {
      setResult(data);

      setTimeout(() => {
        // Make sure rating is available only when response is valid.
        setRateDialog(true);
      }, 10000);
    });
  }

  const handleRating = async (score, text) => {
    if (score) {
      setRateDialog(false);
      Api.rateQuestionnaire(result.user_questionnaire_id, { score, text });
    }
  };

  useActiveSession(() => {
    fetchResultData();
  });

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ผลการประเมิน - MorOnline</title>
      </Helmet>
      <RatingDialog open={!!rateDialog} onSubmit={handleRating} />
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={6}>
          <Logo />
          <Card>
            <CardContent>
              <h2>สรุปผล</h2>
              {result && (
                <>
                  <h2 className={classes.header}>ผลการประเมิน</h2>
                  {result.interprets.map((interpret, index) => (
                    <h3
                      key={index}
                      // className={`${classes.result} ${interpret.score >= 50 ? classes.textRed : classes.textBlue}`}
                      className={`${classes.result}`}
                    >
                      {interpret.interpret}
                    </h3>
                  ))}
                  <Grid
                    container
                    justifyContent='center'
                    direction='column'
                    spacing={2}
                  >
                    {source === 'station' ? (
                      <>
                        <Grid item xs={12}>
                          <CrossPlatformLink to='me.moronline.station://consult'>
                            <Button
                              fullWidth
                              variant='contained'
                              color='primary'
                            >
                              ปรึกษาแพทย์
                            </Button>
                          </CrossPlatformLink>
                        </Grid>
                      </>
                    ) : (
                      <>
                        {
                          !!partnerId ? (
                            <Grid item xs={12}>
                              <CrossPlatformLink
                                to={`me.moronline://partners/consult/category/?partnerId=${partnerId}&categoryId=${result.category.id}`}
                              >
                                <Button
                                  fullWidth
                                  variant='contained'
                                  color='primary'
                                >
                                  ปรึกษาแพทย์
                                </Button>
                              </CrossPlatformLink>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <CrossPlatformLink
                                to={`me.moronline://consult/category/${result.category.id}`}
                              >
                                <Button
                                  fullWidth
                                  variant='contained'
                                  color='primary'
                                >
                                  ปรึกษาแพทย์
                                </Button>
                              </CrossPlatformLink>
                            </Grid>
                          )
                        }
                        <Grid item xs={12}>
                          <Link to='/assessment/menu'>
                            <Button
                              fullWidth
                              variant='contained'
                              color='primary'
                            >
                              ทำแบบประเมินอื่น
                            </Button>
                          </Link>
                        </Grid>
                      </>
                    )}
                    {/* {
                    result.category.show_package && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#1c82bd' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/products/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}&product_type=package`)}
                          variant="contained"
                        >
                          ใช้บริการตรวจสุขภาพที่บ้าน
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.reqeust_contact && (
                      <Grid item xs={12}>
                        <Button
                          className={`${classes.fullButton} ${classes.redButton}`}
                          onClick={() => navigate(`/request_contact?user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          ต้องการให้อาสาสมัครติดต่อกลับ
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_holistic && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#f95e8e' }}
                          className={classes.fullButton}
                          onClick={() => navigate('/holistic')}
                          variant="contained"
                        >
                          ควรดูแลตัวเองอย่างไรต่อ
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_questionnaire && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#78ccff' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/questionnaires/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          ทำแบบประเมินอื่น
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_suggest_friend && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#188822' }}
                          className={classes.fullButton}
                          onClick={() => window.location = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(`${window.location.origin}/questionnaire/?questionnaireId=${questionnaireId}`)}`}
                          variant="contained"
                        >
                          แนะนำเพื่อน
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_blog && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#4677a8' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/blogs/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          อ่านบทความที่เกี่ยวข้อง
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_consultant && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#0dd60d' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/consultants/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          ต้องการปรึกษาผู้เชี่ยวชาญ
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_location && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#3A81EC' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/locations/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          ค้นหา รพ/ศูนย์ ที่เหมาะกับฉัน
                        </Button>
                      </Grid>
                    )
                  }
                  {
                    result.category.show_product && (
                      <Grid item xs={12}>
                        <Button
                          style={{ background: '#17B9A3' }}
                          className={classes.fullButton}
                          onClick={() => navigate(`/products/?category_id=${result.user_answers.questionnaire.category}&user_question_id=${result.user_answers.id}`)}
                          variant="contained"
                        >
                          ค้นหา บริการ/สินค้า ที่ที่เหมาะกับฉัน
                        </Button>
                      </Grid>
                    )
                  } */}
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default QuestionnaireSuccess;
